import FrontendHeader from "./frontend-header";
import FrontendAbout from "./frontend-about";
import "./frontend.css";
import FrontendBottom from "./frontend-bottom";
import FrontendAbout2 from "./frontend-about-2";
import FrontendAbout3 from "./frontend-about-3";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import FrontendVideoSection from "./frontend-video-section";

const scrollTo = (id: string) => {
  const targetDiv = document.getElementById(id);
  if (targetDiv) {
    const container = document.querySelector(".product-list-container");
    if (container) {
      container.scrollTo({
        left: targetDiv.offsetLeft,
        behavior: "smooth",
      });
    }
  }
};
function Frontend() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="App"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <motion.img
        src="https://macefoods.com/wp-content/uploads/2023/05/scroll-down.gif"
        alt="scroll down"
        className="scroll-down"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0, y: 100 }}
        transition={{
          duration: 0.2,
          delay: 2,
        }}
      />
      <div className="frontend-page-container">
        <div className="scroll-section" id="top">
          <FrontendHeader />
        </div>
        <div className="scroll-section">
          <FrontendAbout />
        </div>
        {/* <div className="scroll-section">
          <FrontendAbout2 />
        </div>
        <div className="scroll-section">
          <FrontendAbout3 />
        </div> */}
        <div className="scroll-section" ref={ref}>
          <FrontendVideoSection />
          <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={inView ? { y: 0, opacity: 1 } : { y: 0 }}
            transition={{ delay: 0.8, duration: 1, type: "tween" }}
          >
            <a href="#top" className="to-top" onClick={() => scrollTo("top")}>
              TO TOP
            </a>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default Frontend;
