import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import "./design.css";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

function DesignBottom() {
  const controls = useAnimation();

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="white-container">
      <div className="design-bottom-content" ref={ref}>
        <motion.p
          style={{ width: "90%", textAlign: "start", justifyContent: "start" }}
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: 1, duration: 4 }}
        >
          I developed my design expertise and built my skill set through
          hands-on experience. Starting with a background in photography.
        </motion.p>
        <div>
          <motion.p
            style={{ width: "100%", textAlign: "end", justifyContent: "end" }}
            ref={ref}
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ delay: 1, duration: 4 }}
          >
            I have spent years honing my craft, building brands,
            <br /> and mastering design tools—particularly Adobe software.
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default DesignBottom;
