import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ImageCycle from "../hooks/animated-routes/image-cycle";
import FigmaIcon from "./Assets/figma-icon.png";
import MilanoteIcon from "./Assets/milanote-icon.png";
import IndesignIcon from "./Assets/indesign-icon.png";
import PhotoshopIcon from "./Assets/photoshop-icon.png";
import LightroomIcon from "./Assets/lightroom-icon.png";
import "./design.css";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

const images = [
  FigmaIcon,
  MilanoteIcon,
  IndesignIcon,
  PhotoshopIcon,
  LightroomIcon,
];
function DesignLanding() {
  const controls = useAnimation();

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="design-landing-container scroll-section">
      {/* <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 1, delay: 2 }}
        className="title-container-L"
      >
        <div>
          <em className="design-title">Design</em>
        </div>
      </motion.div> */}

      <div className="title-container-R design-clipped-text " ref={ref}>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          FIGMA
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          MILANOTE
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.9 }}
        >
          INDESIGN
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.1 }}
        >
          PHOTOSHOP
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.3 }}
        >
          LIGHTROOM
        </motion.h1>
      </div>
      <motion.div
        style={{ width: "50px", height: "50px", zIndex: 99 }}
        className="icon-cycle-design"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.5 }}
      >
        <ImageCycle images={images} />
      </motion.div>
    </div>
  );
}

export default DesignLanding;
