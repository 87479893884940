import { useEffect } from "react";
import "./git-hub.css";
import TodoListVideo from "./assets/Todo List.mp4";
import WeatherAppVideo from "./assets/Weather App.mp4";
import PortfolioVideo from "./assets/portfolio.mp4";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function GitHubVideoSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const scrollTo = (id: string) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      const container = document.querySelector(".product-list-container");
      if (container) {
        container.scrollTo({
          left: targetDiv.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <>
      <div className="white-container">
        <div className="section-container">
          <div className="product-list-container ">
            <div className="product-list">
              <div
                className="product-card  scroll-container slides fade"
                id="1"
              >
                <a href="https://github.com/ArranStrange/weather-api-app">
                  <video
                    src={WeatherAppVideo}
                    // autoPlay
                    muted
                    loop
                    playsInline
                    controls={true}
                    className="video git-hub-video"
                  />
                </a>
                <h5>WEATHER APP</h5>
                <div className="text">
                  Weather Web App
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">AXIOS</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="2">
                <a href="https://github.com/ArranStrange/todo-list">
                  <video
                    src={TodoListVideo}
                    // autoPlay
                    muted
                    loop
                    playsInline
                    controls={true}
                    className="video"
                  />
                </a>
                <h5>TODO LIST APP</h5>
                <div className="text">
                  TODO LIST APP
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dot-container">
            <div className="dot" onClick={() => scrollTo("1")} />
            <div className="dot" onClick={() => scrollTo("2")} />
          </div>
        </div>
      </div>
    </>
  );
}
