import "./about-me.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

// Motion Framer object to control visible & hidden
const fadeInVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 5 } },
  hidden: { opacity: 0, scale: 1 },
};

function AboutMeBottom() {
  // import Motion Framer useAnimation hook
  // import inView from react-intersection-observer to ref view point - returns boolean true if in view, false if not.
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    // if statement to set use object visible when useInView hoot return true
    if (inView) {
      controls.start("visible");
    }
    // dependencies
  }, [controls, inView]);

  return (
    <div className="about-me-bottom-container">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={fadeInVariants}
        className="about-me-bottom-content"
      >
        <p style={{ textAlign: "start" }}>
          With a focus on web design and front-end development, I specialise in
          creating dynamic, visually compelling digital experiences.
        </p>
        <p style={{ textAlign: "end" }}>
          My work combines innovative design, smooth animations, and intuitive
          functionality to bring ideas to life on the web.
        </p>
      </motion.div>
      {/* ADD TO TOP TO THE MAIN PAGE OF EACH SECTION TO ALLOW SCROLL */}
    </div>
  );
}

export default AboutMeBottom;
