import BrandingVideo from "./Assets/Branding.mp4";
import WebVideo from "./Assets/Web.mp4";
import PrintVideo from "./Assets/Print.mp4";
import SocialVideo from "./Assets/Socials.mp4";
import { FaArrowCircleRight } from "react-icons/fa";
/* eslint-disable jsx-a11y/anchor-has-content */
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export default function FrontendVideoSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const scrollTo = (id: string) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      const container = document.querySelector(".product-list-container");
      if (container) {
        container.scrollTo({
          left: targetDiv.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <>
      <div className="black-container">
        <div className="section-container">
          <div className="product-list-container">
            <div className="product-list">
              <div
                className="product-card scroll-container slides fade"
                id="1"
                ref={ref}
              >
                <video
                  src={PrintVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5 className="">PRINT</h5>
              </div>
              <div className="product-card scroll-container slides fade" id="2">
                <video
                  src={WebVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5 className="">WEB</h5>
              </div>
              <div className="product-card scroll-container slides fade" id="3">
                <video
                  src={SocialVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5>SOCIALS</h5>
              </div>
              <div className="product-card scroll-container slides fade" id="4">
                <video
                  src={BrandingVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <h5>BRANDING</h5>
              </div>
            </div>
          </div>
          <div>
            <div className="dot" onClick={() => scrollTo("1")}></div>
            <div className="dot" onClick={() => scrollTo("2")}></div>
            <div className="dot" onClick={() => scrollTo("3")}></div>
            <div className="dot" onClick={() => scrollTo("4")}></div>
          </div>
        </div>
      </div>
    </>
  );
}
