import "./frontend.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

function FrontendAbout() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <div className="white-container">
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 4 }}
        className="frontend-bottom-content"
      >
        <motion.p
          style={{ textAlign: "start" }}
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 4 }}
        >
          My goal is to design exceptional user experiences for visually
          stunning applications. I am passionate about minimalism, clean user
          interfaces, and impactful typography.
        </motion.p>
        <motion.p
          style={{ width: "100%", textAlign: "end", justifyContent: "end" }}
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: 1, duration: 4 }}
        >
          My focus is on delivering seamless functionality <br /> that enables
          engaging and intuitive user journeys.
        </motion.p>
        <motion.p
          style={{ textAlign: "start" }}
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: 2, duration: 4 }}
        >
          I specialise in creating applications that blend design and
          engineering seamlessly—products that are both visually impressive and
          well-built at their core.
        </motion.p>
      </motion.div>
    </div>
  );
}

export default FrontendAbout;
