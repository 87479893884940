/* eslint-disable jsx-a11y/anchor-has-content */
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import SinNombreVideo from "./Assets/Sin Nombre.mp4";
import TodoListVideo from "./Assets/Todo List.mp4";
import WeatherAppVideo from "./Assets/Weather App.mp4";
import BadMoonVideo from "./Assets/Bad-Moon-video-1.mp4";
import StickyFingersVideo from "./Assets/Sticky Fingers.mp4";
import SmallParadeVideo from "./Assets/smallparadevideo.mp4";
import LittleBlackBookVideo from "./Assets/Little Black Book.mov";

export default function FrontendVideoSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const scrollTo = (id: string) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      const container = document.querySelector(".product-list-container");
      if (container) {
        // Calculate the target scroll position
        const targetPosition = targetDiv.offsetLeft;

        // Animate the scroll using Framer Motion
        container.scrollTo({
          left: targetPosition,
          behavior: "smooth", // Smooth scrolling
        });
      }
    }
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <>
      <div className="black-container">
        <div className="section-container">
          <motion.div
            className="product-list-container"
            style={{ display: "flex", overflowX: "auto", overflowY: "hidden" }}
          >
            <div className="product-list">
              <div
                className="product-card scroll-container slides fade"
                id="1"
                ref={ref}
              >
                <video
                  src={LittleBlackBookVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <a href="https://black-book-1454c.web.app/">
                  <h5 className="title-link">
                    Little Black Book (Full Stack) - Click Here!
                  </h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">MongoDB</span>
                  <span className="project-tech-stack">Axios</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">Firebase</span>
                  <span className="project-tech-stack">Render</span>
                </div>
              </div>
              <div
                className="product-card scroll-container slides fade"
                id="2"
                ref={ref}
              >
                <video
                  src={StickyFingersVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <a href="https://sticky-fingers-c4170.web.app/">
                  <h5 className="title-link">Sticky Fingers - Click Here!</h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">Framer Motion</span>
                </div>
              </div>
              <div
                className="product-card scroll-container slides fade"
                id="3"
                ref={ref}
              >
                <video
                  src={SmallParadeVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <a href="https://small-parade-web-design.web.app/">
                  <h5 className="title-link">Small Parade - Click Here!</h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">GSAP</span>
                  <span className="project-tech-stack">Framer Motion</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="4">
                <video
                  src={WeatherAppVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />

                <a href="https://arranstrange.github.io/weather-api-app/">
                  <h5 className="title-link">WEATHER APP - Click Here!</h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">AXIOS</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="5">
                <video
                  src={TodoListVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />

                <a href="https://arranstrange.github.io/todo-list/">
                  <h5 className="title-link">TODO LIST APP - Click Here!</h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                </div>
              </div>
              <div
                className="product-card scroll-container slides fade"
                id="6"
                ref={ref}
              >
                <video
                  src={BadMoonVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <a href="">
                  <h5 className="title-link">BAD MOON</h5>
                </a>
                <div className="text">
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                  <span className="project-tech-stack">GSAP</span>
                  <span className="project-tech-stack">Framer Motion</span>
                </div>
              </div>
              <div className="product-card scroll-container slides fade" id="7">
                <video
                  src={SinNombreVideo}
                  // autoPlay
                  muted
                  loop
                  playsInline
                  controls={true}
                  className="video"
                />
                <a href="https://sin-nombre-7aefb.web.app/">
                  <h5 className="title-link">Sin Nombre - Click Here!</h5>
                </a>
                <div className="text">
                  Static Site
                  <span className="project-tech-stack">HTML</span>
                  <span className="project-tech-stack">Tailwind CSS</span>
                  <span className="project-tech-stack">Typescript</span>
                  <span className="project-tech-stack">React</span>
                </div>
              </div>
            </div>
          </motion.div>
          <div>
            <div className="dot" onClick={() => scrollTo("1")}></div>
            <div className="dot" onClick={() => scrollTo("2")}></div>
            <div className="dot" onClick={() => scrollTo("3")}></div>
            <div className="dot" onClick={() => scrollTo("4")}></div>
            <div className="dot" onClick={() => scrollTo("5")}></div>
            <div className="dot" onClick={() => scrollTo("6")}></div>
            <div className="dot" onClick={() => scrollTo("7")}></div>
          </div>
        </div>
      </div>
    </>
  );
}
